import React from "react";
import {Helmet} from "react-helmet";

const Index = () => {
    return <>
        <Helmet>
            <title>Страница не найдена</title>
        </Helmet>
        <h1>Страница не найдена</h1>
    </>
};

export default Index;

import React from "react";
import "moment/locale/ru";
import {Flight} from "./index";
import FlightRow from "./FlightRow";

const FlightTable = ({flights}: { flights: Flight[] }) => {
    return <>
        <table className="table table-hover my-3">
            <thead>
            <tr>
                <th className="text-center">Рейс</th>
                <th className="text-center">Авиакомпания</th>
                <th className="text-center">Отправление</th>
                <th className="text-center">Прибытие</th>
                <th>Статус</th>
            </tr>
            </thead>
            <tbody>
            {flights.map((flight, i) =>
                <FlightRow key={i} flight={flight}/>
            )}
            </tbody>
        </table>
    </>
};

export default FlightTable;

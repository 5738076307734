import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import FlightsTable from "../Airport/FlightsTable";
import {Flight, PagedFlights} from "../Airport";

const Index = () => {
    const {code} = useParams<{ code: string }>();
    const [data, setData] = useState<PagedFlights | null>(null);
    useEffect(() => {
        fetch(`/common/v1/flight/list.json?query=${code}&fetchBy=flight`)
            .then(res => res.json())
            .then(res => {
                console.dir(res);
                let newData: PagedFlights = {
                    pager: {
                        current: res.result.response.page.current,
                        total: res.result.response.page.current
                    },
                    flights: res.result.response.data.map((item: any): Flight | boolean => {
                        let destination = null;
                        let origin = null;
                        if (item.airport !== undefined) {
                            destination = {
                                name: item.airport.destination.name,
                                code: item.airport.destination.code
                            };
                            origin = {
                                name: item.airport.origin.name,
                                code: item.airport.origin.code
                            };
                        }
                        let time = null;
                        if (item.time !== undefined) {
                            time = {
                                estimated: {
                                    departure: new Date(item.time.estimated.departure * 1000),
                                    arrival: new Date(item.time.estimated.arrival * 1000)
                                },
                                real: {
                                    departure: new Date(item.time.real.departure * 1000),
                                    arrival: new Date(item.time.real.arrival * 1000)
                                },
                                scheduled: {
                                    departure: new Date(item.time.scheduled.departure * 1000),
                                    arrival: new Date(item.time.scheduled.arrival * 1000)
                                }
                            };
                        }
                        return {
                            airline: item.airline?.name ?? null,
                            destination: destination,
                            origin: origin,
                            number: item.identification.number.default,
                            status: item.status?.text ?? null,
                            time: time
                        };
                    })
                };
                setData(newData);
            })
    }, [code]);
    return <>
        <Helmet>
            <title>Рейс {code}: Табло прилета и вылета</title>
        </Helmet>
        <h1>Рейс {code}</h1>
        <div className="lead">Табло прилета и вылета</div>
        {data !== null
            ? <>
                <div className="my-3">
                    <FlightsTable flights={data.flights}/>
                </div>
            </>
            : <>
                <FontAwesomeIcon icon={faCircleNotch} className="text-primary fa-spin"/>
            </>
        }
    </>
};

export default Index;

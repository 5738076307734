export interface Airport {
    name: string
    code: string
}

const Airports: Airport[] = [{
        "code": "dme", "name": "Москва, Домодедово"
    }, {
        "code": "svo", "name": "Москва, Шереметьево"
    }, {
        "code": "led", "name": "Санкт-Петербург, Пулково"
    }, {
        "code": "vko", "name": "Москва, Внуково"
    }, {
        "code": "svx", "name": "Екатеринбург, Кольцово"
    }, {
        "code": "ovb", "name": "Новосибирск, Толмачёво"
    }, {
        "code": "krr", "name": "Краснодар, Пашковский"
    }, {
        "code": "aer", "name": "Сочи"
    }, {
        "code": "sip", "name": "Симферополь"
    }, {
        "code": "ufa", "name": "Уфа"
    }, {
        "code": "kuf", "name": "Самара, Курумоч"
    }, {
        "code": "rov", "name": "Ростов-на-Дону"
    }, {
        "code": "kja", "name": "Красноярск, Емельяново"
    }, {
        "code": "khv", "name": "Хабаровск, Новый"
    }, {
        "code": "kzn", "name": "Казань"
    }, {
        "code": "mrv", "name": "Минеральные Воды"
    }, {
        "code": "vvo", "name": "Владивосток"
    }, {
        "code": "ikt", "name": "Иркутск"
    }, {
        "code": "kgd", "name": "Калининград, Храброво"
    }, {
        "code": "cek", "name": "Челябинск"
    }, {
        "code": "tjm", "name": "Тюмень, Рощино"
    }, {
        "code": "sgc", "name": "Сургут"
    }, {
        "code": "pee", "name": "Пермь, Большое Савино"
    }, {
        "code": "goj", "name": "Нижний Новгород, Стригино"
    }, {
        "code": "oms", "name": "Омск, Омск-Центральный"
    }, {
        "code": "aaq", "name": "Анапа, Витязево"
    }, {
        "code": "yks", "name": "Якутск"
    }, {
        "code": "uus", "name": "Южно-Сахалинск, Хомутово"
    }, {
        "code": "arh", "name": "Архангельск, Талаги"
    }, {
        "code": "nux", "name": "Новый Уренгой"
    }, {
        "code": "csy", "name": "Чебоксары"
    }]
;

export default Airports;

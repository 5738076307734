import React from 'react';

import 'bootstrap';
import './App.scss';
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlane} from '@fortawesome/free-solid-svg-icons';

import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Airport from "./pages/Airport";
import Flight from "./pages/Flight";

import Airports from "./data/Airports";
import {Helmet} from "react-helmet";

function App() {
    return (
        <BrowserRouter>
            <Helmet>
                <title>Табло прилета и вылета</title>
            </Helmet>
            <nav className="navbar navbar-light bg-light mb-3 shadow-sm border-bottom">
                <div className="container">
                    <Link to={"/"} className="navbar-brand text-uppercase">
                        <FontAwesomeIcon icon={faPlane} className="me-2 text-info"/>
                        Табло24
                    </Link>
                </div>
            </nav>
            <div className="container">
                <Switch>
                    <Route path={"/"} exact={true} component={Home}/>
                    <Route sensitive={true} path={"/flight/:code([0-9A-Z]+)"} exact={true} component={Flight}/>
                    {Airports.map((airport) => <Route
                        sensitive={true}
                        key={airport.code}
                        path={`/airport/${airport.code}`}
                        render={(props) => <Airport airport={airport} {...props}/>}
                    />)}
                    <Route component={Error404}/>
                </Switch>
            </div>
        </BrowserRouter>
    );
}


export default App;

import React, {useEffect, useState} from "react";
import {Airport} from "../../data/Airports";
import {Helmet} from "react-helmet";
import {Redirect, Route, Switch} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch, faPlaneArrival, faPlaneDeparture} from '@fortawesome/free-solid-svg-icons';
import "moment/locale/ru";
import FlightsTable from "./FlightsTable";

interface Pager {
    current: number
    total: number
}

interface Timing {
    departure: Date | null
    arrival: Date | null
}

export interface Flight {
    number: string
    status: string | null
    time: {
        scheduled: Timing
        real: Timing
        estimated: Timing
    } | null
    airline: string | null
    origin: Airport | null
    destination: Airport | null
}

export interface PagedFlights {
    pager: Pager
    flights: Flight[]
}

interface AirportData {
    arrivals: PagedFlights
    departures: PagedFlights
    ground: PagedFlights
}

function mapFlight(item: any): Flight | boolean {
    if (item.flight.identification.number.default === null) {
        return false;
    }
    let time = null;
    if (item.flight.time !== undefined) {
        time = {
            estimated: {
                departure: new Date(item.flight.time.estimated.departure * 1000),
                arrival: new Date(item.flight.time.estimated.arrival * 1000)
            },
            real: {
                departure: new Date(item.flight.time.real.departure * 1000),
                arrival: new Date(item.flight.time.real.arrival * 1000)
            },
            scheduled: {
                departure: new Date(item.flight.time.scheduled.departure * 1000),
                arrival: new Date(item.flight.time.scheduled.arrival * 1000)
            }
        };
    }
    let destination = null;
    let origin = null;
    if (item.flight.airport !== undefined) {
        destination = {
            name: item.flight.airport.destination.name,
            code: item.flight.airport.destination.code
        };
        origin = {
            name: item.flight.airport.origin.name,
            code: item.flight.airport.origin.code
        };
    }
    return {
        airline: item.flight.airline?.name ?? null,
        destination: destination,
        origin: origin,
        status: item.flight.status?.text ?? null,
        time: time,
        number: item.flight.identification.number.default
    }
}

const Index = ({airport}: { airport: Airport }) => {
    const [data, setData] = useState<AirportData | null>(null);
    useEffect(() => {
        fetch(`/common/v1/airport.json?code=${airport.code}`)
            .then(res => res.json())
            .then(res => {
                let newData: AirportData = {
                    arrivals: {
                        pager: {
                            current: res.result.response.airport.pluginData.schedule.arrivals.page.current,
                            total: res.result.response.airport.pluginData.schedule.arrivals.page.total
                        },
                        flights: res.result.response.airport.pluginData.schedule.arrivals.data.map(mapFlight),
                    },
                    departures: {
                        pager: {
                            current: res.result.response.airport.pluginData.schedule.departures.page.current,
                            total: res.result.response.airport.pluginData.schedule.departures.page.total
                        },
                        flights: res.result.response.airport.pluginData.schedule.departures.data.map(mapFlight),
                    },
                    ground: {
                        pager: {
                            current: res.result.response.airport.pluginData.schedule.ground.page.current,
                            total: res.result.response.airport.pluginData.schedule.ground.page.total
                        },
                        flights: res.result.response.airport.pluginData.schedule.ground.data.map(mapFlight),
                    },
                };
                setData(newData);
            })
    }, [airport.code]);
    return <>
        <Helmet>
            <title>Аэропорт {airport.name} ({airport.code.toUpperCase()}): Табло прилета и вылета</title>
        </Helmet>
        <Switch>
            <Route path={"/airport/:code"} exact={true}>
                <h1>Аэропорт {airport.name} ({airport.code.toUpperCase()})</h1>
                <div className="lead">Табло прилета и вылета</div>
                {data !== null
                    ? <>
                        <div className="my-3">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab"
                                        data-bs-target="#departures" type="button" role="tab" aria-controls="nav-home"
                                        aria-selected="true">
                                    <FontAwesomeIcon icon={faPlaneDeparture} className="me-2"/>
                                    Отправление
                                </button>
                                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab"
                                        data-bs-target="#arrivals" type="button" role="tab"
                                        aria-controls="nav-profile" aria-selected="false">
                                    <FontAwesomeIcon icon={faPlaneArrival} className="me-2"/>
                                    Прибытие
                                </button>
                            </div>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="departures" role="tabpanel"
                                     aria-labelledby="nav-home-tab">
                                    <FlightsTable flights={data.departures.flights}/>
                                </div>
                                <div className="tab-pane fade" id="arrivals" role="tabpanel"
                                     aria-labelledby="nav-profile-tab">
                                    <FlightsTable flights={data.arrivals.flights}/>
                                </div>
                            </div>
                        </div>
                    </>
                    : <>
                        <FontAwesomeIcon icon={faCircleNotch} className="text-primary fa-spin"/>
                    </>}

            </Route>
            <Route>
                <Redirect to={`/airport/${airport.code}`}/>
            </Route>
        </Switch>
    </>
};

export default Index;

import React from "react";
import {Link} from "react-router-dom";
import Moment from "moment";
import "moment/locale/ru";
import {Flight} from "./index";

const FlightRow = ({flight}: { flight: Flight }) => {
    const today = new Date();
    return <>
        <tr>
            <td className="text-center">
                <Link to={`/flight/${flight.number}`}>{flight.number}</Link>
            </td>
            <td className="text-center">
                {flight.airline ?? "-"}
            </td>
            <td className="text-center">
                {Moment(flight.time?.scheduled.departure).format("HH:mm")}
                {(flight.time?.scheduled.departure?.getFullYear() !== today.getFullYear()
                    || flight.time?.scheduled.departure?.getMonth() !== today.getMonth()
                    || flight.time?.scheduled.departure.getDate() !== today.getDate()) &&
                <span className="text-muted ms-1 small">
                    {Moment(flight.time?.scheduled.departure).format("DD MMM")}
                </span>
                }
            </td>
            <td className="text-center">
                {Moment(flight.time?.scheduled.arrival).format("HH:mm")}
                {(flight.time?.scheduled.arrival?.getFullYear() !== today.getFullYear()
                    || flight.time?.scheduled.arrival?.getMonth() !== today.getMonth()
                    || flight.time?.scheduled.arrival.getDate() !== today.getDate()) &&
                <span className="text-muted ms-1 small">
                    {Moment(flight.time?.scheduled.arrival).format("DD MMM")}
                </span>
                }
            </td>
            <td>
                {flight.status}
            </td>
        </tr>
    </>
};

export default FlightRow;

import React from "react";
import Airports from "../../data/Airports";
import {Link} from "react-router-dom";

const Index = () => {
    return <>
        <h1>Онлайн табло прилетов и вылетов</h1>
        <div className="list-group my-3">
            {Airports.map((airport) =>
                <Link key={airport.code} className="list-group-item list-group-item-action text-primary"
                      title={`Аэропорт ${airport.name} (${airport.code.toUpperCase()}): Онлайн табло прилетов и вылетов`}
                      to={`/airport/${airport.code}`}>
                    {airport.name}
                </Link>
            )}
        </div>
    </>
};

export default Index;
